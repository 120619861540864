@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * Disable zoom from field input on mobile
 */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[role="combobox"] {
    font-size: 16px !important;
  }
}
